
/**
 * Global variables
 */
// Break points
$grid-breakpoints: (
  zero: 0,
  xsmall: 320px,
  small: 576px,
  medium: 768px,
  large: 992px,
  mLarge: 1024px,
  xlarge: 1200px,
  xxlarge: 1900px
);

$site-width: 1300px;
$big-site-width: 1900px;

// Colors
$brand-color-00: #ffffff;
$brand-color-01: #276092;
$brand-color-02: #276092;
$brand-color-03: #ee3124;
$brand-color-04: #303030;
$brand-color-05: #d6d6d6;
$brand-color-06: #222222;
$brand-color-07: #999999;
$brand-color-08: #666666;
$brand-color-09: #f4f4f4;
$brand-color-10: #dedede;
$brand-color-11: #e6e6e6;
$brand-color-12: #f0f0f0;
$brand-color-13: #3f3f3f;
$brand-color-14: #cccccc;
$brand-color-15: #dddddd;
$brand-color-16: #464646;
$brand-color-17: #ededed;
$brand-color-18: #efefef;
$brand-color-19: #e3e3e3;
$brand-color-20: #fcfcfc;
$brand-color-21: #d4d4d4;
$brand-color-22: #d2d2d2;
$brand-color-23: #bfbfbf;
$brand-color-24: #000000;
$brand-color-25: #fafafa;
$brand-color-26: #c91d1d;
$brand-color-27: #f6f6f6;
$brand-color-28: #7b7b7b;



// Button Colors
$btn-color-00: $brand-color-00;
$btn-color-01: #f7991c;
$btn-color-02: #0cae5b;
$btn-color-03: $brand-color-26;
$btn-color-04: $brand-color-05;
$btn-color-05: $brand-color-19;
$btn-color-06: #fdcb27;
$btn-color-07: $brand-color-11;

$btn-color-01--hover: #ff9200;

$bg-color-00: $brand-color-00;
$bg-color-01: $brand-color-09;
$bg-color-02: #e7e7e7;
$bg-color-03: $brand-color-02;
$bg-color-04: $brand-color-18;
$bg-color-05: $brand-color-19;
$bg-color-06: #dfdfdf;

$highlight-color-01: #eef2f6;
$highlight-color-02: #87a11e;
$highlight-color-03: #d50810;

// Text, fonts
$default-font-color-00: $brand-color-00;
$default-font-color-01: #303030;
$default-font-color-02: $brand-color-08;
$default-font-color-03: $brand-color-05;
$default-font-color-04: $brand-color-19;
$default-font-color-05: $brand-color-07;
$default-font-color-06: #8f8f8f;
$default-font-color-07: #bababa;
$default-font-color-08: #848484;

$default-font: "Neue Helvetica W01", "Helvetica Neue", Helvetica, Arial, sans-serif;
$icon-material: "Material-Design-Iconic-Font";
$icon-fa-free: "Font Awesome 5 Free";
$icon-fa-brands: "Font Awesome 5 Brands";
$default-font-size: 12px;
$default-line-height: 1.3em;

// Z index levels
$level-base: 0;
$level-middle: 100; 
$level-top: 200; 
$level-cover: 500;

// Mobile styling
$padding-mobile: 22px;
$padding-mobile-nav: 14px 20px;
$font-size-mobile-nav: 12px;

// transition easing
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);



