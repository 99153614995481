@import "../main/main-variables-mixins.scss";
@import "../sections/sections-variables-mixins.scss";

.mf-home {


  //legacy variables, can be deleted
  $brandingHvacr:			#0397D7;
  $brandingBathrooms:		#db1e24;
  $brandingPlumbing:		#0d2e56;
  $brandingIrrigation:	#5e792d;
  $brandingOnsite:		#f47836;
  $brandingCivil:			#b65312;
  $brandingMyAccount:     #7c99c1;

  // Content Wrapper Module (Define)
  //====================================================================================================================================
  //====================================================================================================================================

  .main-header .header-container,
  .main-footer .footer-container,
  .masonry-container,
  .content-container,
  .navbar-container {
    max-width: 1200px;
  }


  .content-container .inner-wrapper {
    position: relative;
    margin: 0 10px;
    padding: 10px;
  }

  // Features
  .features > div {
    padding-right: .5%;
    padding-left: .5%;
  }

  h1{
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
  }

  /* Extra Small Devices, Phones */
  @media only screen and (max-width : 480px) {
    h1{
      font-size: 24px;
    }
  }


  // Content after main banner
  //====================================================================================================================================
  //====================================================================================================================================

  #home-strengths{
    margin-top: 20px;
  }



  // Title item Module
  //====================================================================================================================================
  //====================================================================================================================================

  .tile-item {
    position: relative;
    box-shadow: 0 1px 4px rgba(0,0,0,0.35);
    -webkit-transition: .25s box-shadow ease-in-out;
    -moz-transition: .25s box-shadow ease-in-out;
    -o-transition: .25s box-shadow ease-in-out;
    -ms-transition: .25s box-shadow ease-in-out;
    transition: .25s box-shadow ease-in-out;

    a.primary-link {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
  }

  // Feature Item Module
  //====================================================================================================================================
  //====================================================================================================================================

  .feature-item {
    position: relative;
    border: 3px solid #fff;

    &.last {
      margin-right: 0px !important;
    }

    .caption {
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      background: rgba(39,96,146, 0.9) none repeat scroll 0 0;
      padding: 12px 15px 12px 15px;
      height: 42px;
      color: #fff;
      text-align: center;

      h4 {
        position: absolute;
        width: 100%;
        bottom: 12px;
        left: 0;
        margin: 0;
        font-size: 14px;
        text-align: center;
        color: white;
        font-weight: normal;
        text-transform: uppercase;
      }

      /* Medium Devices, Desktops */
      @media only screen and (max-width : 992px) {
        h4{
          font-size: 13px;
          line-height: 14px;
        }
      }
    }


    &:hover .caption {
      height: 54px;
      -webkit-transition: height 200ms ease-out;
      -moz-transition: height 200ms ease-out;
      -ms-transition: height 200ms ease-out;
      -o-transition: height 200ms ease-out;
      transition: height 200ms ease-out;
    }

    .form-wrapper {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 0;
      width: 100%;
      text-align: center;
      height: 120px;
      margin-top: -60px;

      h4 {
        color: white;
      }
    }

    .caption.no-border {
      border: none;
    }

    .form-wrapper select {
      margin: 0 auto 10px auto !important;
    }
  }

  .feature-hvacr .caption {
    background-color: $brandingHvacr;
  }
  .feature-bathrooms .caption {
    background-color: $brandingBathrooms;
  }
  .feature-plumbing .caption {
    background-color: $brandingPlumbing;
  }
  .feature-irrigation .caption {
    background-color: $brandingIrrigation;
  }
  .feature-onsite .caption {
    background-color: $brandingOnsite;
  }
  .feature-civil .caption {
    background-color: $brandingCivil;
  }
  .feature-my-account .caption {
    background-color: $brandingMyAccount;
  }

  // Specialised Item Module (Sub module styles for .featured-item)
  //====================================================================================================================================
  //====================================================================================================================================

  .specialised  {

    .bottom-space {
      padding:0;
      margin-bottom: 9px;
    }

    .feature-item {
      position: relative;
      margin-right: 3px;
    }

    .feature-item.last{
      margin-left: 3px;
      margin-right: 0;
    }
  }

  // Storefinder Module (Sub module styles for .featured-item)
  //====================================================================================================================================
  //====================================================================================================================================

  .feature-storefinder .caption {
    position: absolute;
    left: 0;
    right: 0;
    top: 65px;
    bottom: auto;
    height: auto;
    padding: 5px 15px;
    color: white;
    text-align: center;
    background-color: transparent;

    &:hover .caption {
      height: auto;
    }

    h4 {
      font-size: 16px;
      color: #fff;
      padding: 0 0 15px 0;
      margin: 0;
    }
  }


  // Grey row section
  //====================================================================================================================================
  //====================================================================================================================================

  .home-grey-row{
    background-color: #EAEAEA;
    color: #666666;

    .row{
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }



  // Home Page Mobile Styles
  //====================================================================================================================================
  //====================================================================================================================================
  //====================================================================================================================================



  // Main Options List Module
  //====================================================================================================================================
  //====================================================================================================================================

  .main-options-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0;
      position: relative;
      border-bottom: 1px solid #333333;
      width: 50%;
      float: left;
      background-repeat: no-repeat;

      a {
        display: block;
        position: relative;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        color: white;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -ms-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;

        &:hover, &:focus, &:active {
          text-decoration: none;
          border-width: 1em;
          color: fade(white, 60%);
        }

      }

      /* Extra Small Devices, Phones */
      @media only screen and (max-width : 480px) {
        a{
          font-size: 12px;
        }
      }

      span {
        display: block;
        position: absolute;
        right: 5px;
        margin-top: -10px;
        top: 50%;
        width: 20px;
        height: 20px;
        background: url('../assets/img/home/arrow.png') 0 0 no-repeat scroll;
        background-size: 100%;
      }

      &.account {
        width: 100%;

        a {
          border-left: none; border-top: 1px solid #999; text-align: center; padding: 0.3em; color: #fff; font-size: 1.2em;
          background: #848484;
          background: -moz-linear-gradient(top,  #848484 0%, #555555 100%);
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#848484), color-stop(100%,#555555));
          background: -webkit-linear-gradient(top,  #848484 0%,#555555 100%);
          background: -o-linear-gradient(top,  #848484 0%,#555555 100%);
          background: -ms-linear-gradient(top,  #848484 0%,#555555 100%);
          background: linear-gradient(to bottom,  #848484 0%,#555555 100%);
        }

        span {
          position: static;
          margin-top: 0;
          margin-right: 10px;
          float: none;
          display: inline-block;
          height: 44px;
          width: 44px;
          vertical-align: middle;
          background-image: url('../assets/img/home/account-icon.png');
          background-color: transparent;
        }
      }

      &.cat-block {
        position: relative;
        overflow: hidden;
        border-left: 1px #686868 solid;

        a {
          position: relative;
          width: 100%;
          height: 100%;
        }

        label {
          position: absolute;
          width: 100%;
          bottom: 0;
          padding: 1em 0em 1em 1em;
          border-left: 0;
          background: rgba(39,96,146, 0.9) none repeat scroll 0 0;
          margin-bottom: 0;
          margin-left: 0;
          cursor: hand;
        }
      }

      &.find-store {
        width: 100%;
        font-size: 1.4em;

        h2 {
          display: inline-block;
          width: 100%;
          margin-top: 0;
          font-size: 1.0em;
          color: $white;

          span {
            float:left;
            width:auto;
            height: auto;
            background: none;
          }
        }

        h3 {
          margin: 0.8em 0 0.5em 0;
          font-size: 0.8em;
        }

        h4 {
          font-size: 0.8em;
        }

        button {
          display: block;
          margin-bottom: 10px;
          font-size: 12px;
          border: 0;
          padding: 4px 10px;
          color: $white;
          background: $grayDark;
        }

        a span {
          display: inline-block;
          float: none;
          margin-bottom: -5px;
        }

        p {
          font-size: 0.7em; margin: 0.5em 0;
        }

        form {
          margin-bottom: 30px;
        }

        .more-options {
          padding: 4px 18px;
        }

        .top {
          padding: 1em;
          background: #848484;
          background: -webkit-gradient(linear, left top, left bottom, from(#848484), to(#555555));
          background: -webkit-linear-gradient(top, #848484, #555555);
          background: -moz-linear-gradient(top, #848484, #555555);
          background: -ms-linear-gradient(top, #848484, #555555);
          background: -o-linear-gradient(top, #848484, #555555);
          background: linear-gradient(top, #848484, #555555);
          background-position: 95% center;
          background-repeat: no-repeat;
        }

        .top.nz {
          background: -webkit-gradient(linear, left top, left bottom, from(#848484), to(#555555));
          background: -webkit-linear-gradient(top, #848484, #555555);
          background: -moz-linear-gradient(top, #848484, #555555);
          background: -ms-linear-gradient(top, #848484, #555555);
          background: -o-linear-gradient(top, #848484, #555555);
          background: linear-gradient(top, #848484, #555555);
        }
      }

      .more-options-menu {
        padding: 0 1em;
        text-align: center;

        span {
          float: none;
          position: static;
          width: 100%;
          margin-top: 0;
        }
      }
    }
  }

  // Mobile Carousel Module
  //====================================================================================================================================
  //====================================================================================================================================

  #mobCarousel {
    margin-bottom: 0;
  }


  // Media Queries
  //====================================================================================================================================
  //====================================================================================================================================

  @media (min-width: 1200px) {

    hr { margin: 12px 0; }

    .bottom-space { margin-bottom: 14px; }

  }

  @media (max-width:979px) {

    .toolbox .toolbox-button { top: -36px; right: 50px; }
    .content-container {  min-height: 0; }

    #headCarousel {padding-top: 0;}

    .feature-item .caption h4 {font-size: 14px;}

    .specialised .feature-item .caption {height: 32px;}
    .specialised .feature-item:hover .caption {height: 44px;}
    .specialised .feature-item .caption h4 {font-size: 12px;}


    .feature-item .form-wrapper {height: 100px; margin-top: -50px;}
    .feature-storefinder h4 {font-size: 14px;}

    .form-wrapper select, .form-wrapper input {font-size: 11px;}

    footer {margin-top: 25px;}

  }


  @media (min-width: 768px) and (max-width: 979px) {

    hr { margin: 4px 0; }

  }


  @media (max-width:768px) {

    .content-container { margin-top: 0; min-height: 0; }

    .bottom-space { margin-bottom: 9px; }


    .feature-storefinder .caption {top: 25px;}

  }

  @media (max-width: 767px)
  {

    // GRID & CONTAINERS
    // -----------------
    // Remove width from containers
    .container {
      width: auto;
    }

    // Fluid rows
    .row {
      width: 100%;
    }

    // Undo negative margin on rows and thumbnails
    .row,
    .thumbnails {
      margin-left: 0;
    }
    .thumbnails > li {
      float: none;
      margin-left: 0; // Reset the default margin for all li elements when no .span* classes are present
    }

    // Make all grid-sized elements block level again
    [class*="span"],
    .uneditable-input[class*="span"], // Makes uneditable inputs full-width when using grid sizing
    .row-fluid [class*="span"] {
      float: none;
      display: block;
      width: 100%;
      margin-left: 0;
      @include box-sizing(border-box);
    }

    .col-md-12,
    .row .col-md-12 {
      width: 100%;
      @include box-sizing(border-box);
    }

    .row [class*="offset"]:first-child {
      margin-left: 0;
    }


    .feature-item .form-wrapper {
      top: 110px;
      left: 10px !important;
    }

    .sitemap-footer ul, .sitemap-footer h4 {margin-right: 0;}
    footer {margin-top: 0;}

    .main-options-list li.metalflex-products.cat-block { background-image: url('../assets/img/home/heating-cooling-products-metalflex-tile-mobile.jpg'); background-size: cover; height: 150px; }
    .main-options-list li.metalflex-systems.cat-block { background-image: url('../assets/img/home/heating-cooling-system-metalflex-tile-mobile.jpg'); background-size: cover; height: 150px; }

  }


  @media (max-width: 639px)
  {

    .feature-item, .specialised .feature-item
    {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }

    .specialised .feature-item .caption
    {
      bottom: 23px;
    }

    .specialised .feature-item .caption h4
    {
      font-size: 21px;
    }
    .feature-item h4 {
      color: white;
    }

  }

  @media (max-width: 320px)
  {
    .feature-item i
    {
      display: none;
    }

    #social-footer {
      li {
        margin: 0;
      }
      a {
        padding: 2px;
      }
    }
  }

  #mobileStorefinderSearch,
  .storefinderform {
    select {
      option.bu_P {
        color: $brandingPlumbing;
      }
      option.bu_B {
        color: $brandingBathrooms;
      }
      option.bu_I {
        color: $brandingIrrigation;
      }
      option.bu_O {
        color: $brandingOnsite;
      }
      option.bu_C {
        color: $brandingCivil;
      }
      option.bu_H {
        color: $brandingHvacr;
      }
    }
    input[type="number"] {
      min-width: 140px;
      width: 100%;
    }
    input.searchstores {
      width: 60px;
    }
    .typeahead {
      text-align:left;
    }
  }

}
