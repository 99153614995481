//legacy variables
// Grays
// -------------------------
$black:                 #000;
$grayDarker:            #222;
$grayDark:              #333;
$gray:                  #555;
$grayLight:             #999;
$grayLighter:           #eee;
$white:                 #fff;

$lightBackground: 	#eaeaea;


//global
$mfGlobalBrandColor: #276092;
$mfGlobalBrandColorLighter: #5280A8;
$mfGlobalLinkDefaultColor:   $mfGlobalBrandColor;
$mfGlobalLinkHoverColor:   #333333;
$mfGlobalBrandFont: 'Roboto Slab', serif;

$brandingHvacr:			#0397D7;
$brandingBathrooms:		#db1e24;
$brandingPlumbing:		#0d2e56;
$brandingIrrigation:	#5e792d;
$brandingOnsite:		#f47836;
$brandingCivil:			#b65312;
$brandingMyAccount:     #7c99c1;


// Navbar
// -------------------------
$navbarCollapseWidth:             979px;
$navbarCollapseDesktopWidth:      $navbarCollapseWidth + 1;

// Fonts
$mfFontSizeLarge:         $font-size-large; // ~18px
$mfFontSizeSmall:         $font-size-small; // ~12px
$mfFontSizeMini:          ceil(($font-size-base * 0.75)); // ~11px

//Header nav
$mfHeaderNavMainBackground:   #333333;
$mfMainNavMainFontColor:   #FFFFFF;

//Main nav
$mfMainNavMainBackground:   #333333;
$mfMainNavMainFontColor:   #FFFFFF;
$mfMainNavLinkColor:   #FFFFFF;
$mfMainNavHoverColor:   #FFFFFF;

//mobile
$mfMobileBorderColor:   #FFFFFF;
$mfMobileInvisibleBorderColor:   $mfMainNavMainBackground;


//Sub nav
$mfSubNavMainBackground:   #333333;
$mfSubNavMainFontColor:   #FFFFFF;

//Content
$mfContentMainBackground:   #FFFFFF;
$mfContentMainFontColor:   #333333;

//Footer
$mfFooterSocialBackground:   #cccccc;
$mfFooterSocialLinkColor:   #454545;
$mfFooterSocialHoverColor: $mfGlobalBrandColor;


$mfFooterMainBackground:   #FFFFFF;
$mfFooterNavLinkColor:   #454545;
$mfFooterNavHoverColor: #999;

/*overwriting font awesome path to pull from 1 central place */
$fa-font-path: "../fonts";
