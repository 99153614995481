@import "../../main/main-variables-mixins.scss";

.hero-banner {
  // max-height: 430px;
  width: 100%;

  &__slider {
    
    position: relative;
  }
  
  &__slider-frame {
    
  }

  &__slide {
    width : 100%;
    
  }
  &__slide-img-container {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;

    img {
      width : 100%;
      height: auto;
    }
  }

  &__slide-img--desktop {
    display: none;
    @include breakpoint-up(medium) {
      display: block;
    }
  }

  &__slide-img--mobile {
    display: block;
    @include breakpoint-up(medium) {
      display: none;
    }
  }

  &__nav-arrow {
    @include no-select;
    position: absolute;
    display: block;
    background-color: transparentize($brand-color-20, .75);
    border-radius: 100%;
    min-width: 44px;
    width: 5%;
    max-width: 48px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    z-index: $level-middle;
  }

  &__nav-arrow-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: $default-font-color-06;
    font-size: 40px;
  }

  &__nav-arrow--next {
    right: 5%;
  }

  &__nav-arrow--prev {
    left: 5%;
  }
}